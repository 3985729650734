<script>
export default {
  name: 'UnderAnalysisApplications',
  data () {
    return {
      header: [
        { text: this.$t('applications.header:name'), value: 'name', width: '50%' },
        { text: this.$t('applications.header:step'), value: 'step' },
        { text: this.$t('applications.header:actions'), value: 'action', width: '120px', align: 'center' }
      ]
    }
  },
  computed: {
    userApplications () {
      return this.$store.getters.getUserApplications.filter((application) => { return application.position.status === 'no_filter' }) || []
    }
  },
  methods: {
    loadApplications () {
      this.$store.dispatch('attemptGetUserApplications')
    },
    openMyPosition (position) {
      return position.positionUrl
    }
  },
  created () {
    this.loadApplications()
  }
}
</script>
<template>
  <div class="under-analysis--container">
    <div class="under-analysis--actions">
      <p class="body--1 bold pl-4">{{ $t('under.analysis:support.text') }}</p>
    </div>
    <v-data-table
      class="applications-table--wrapper"
      :headers="header"
      :items="userApplications"
      :items-per-page="99999"
      disable-sort
      hide-default-footer
      hide-default-header
    >
      <template v-slot:header="{ props: { headers } }">
        <thead>
        <tr>
          <th v-for="h in headers" :key="h.value" :width="h.width" :class="{'text-center': h.align === 'center'}">
            <span class="table-header">{{h.text}} </span>
          </th>
        </tr>
        </thead>
      </template>
      <template
        v-if="userApplications.length > 0"
        v-slot:body
      >
        <tbody>
        <tr
          v-for="({ position }, index) in userApplications"
          :key="index"
          class="application-table--line"
        >
          <td class="text-left overflow-text">
            <div class="applications--user-info">
              <div class="image-wrapper">
                <v-img class="applications--user-picture" :src="position.company.logo || defaultAvatarUrl('profile')"></v-img>
              </div>
              <div class="applications-name--wrapper">
                <span class="applications--user-name bold">{{ position.title }}</span>
                <span class="applications--company-name">{{ position.company.name }}</span>
              </div>
            </div>
          </td>
          <td class="text-left">{{ position.status ? $t(`applications.status:${position.status}`) : '-' }}</td>
          <td class="text-center application-actions">
            <v-btn :href="openMyPosition(position)" target="_blank" icon><v-icon class="edit-profile--btn" color="#1200D3">mdi-open-in-new</v-icon></v-btn>
          </td>
        </tr>
        </tbody>
      </template>
      <template
        v-if="userApplications.length === 0"
        v-slot:no-data
      >
        {{ $t('applications.modal.table:no.applications.found') }}
      </template>
    </v-data-table>
  </div>
</template>
<style lang="scss">
.under-analysis--container {
  .under-analysis--actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .order-by--select {
      padding-top: 20px;
      max-width: 215px;
    }
  }
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
    height: 60px;
  }
  .applications-table--wrapper {
   .applications--user-info {
      display: flex;
      align-items: center;
     .applications--user-picture {
       width: 44px;
       height: 44px;
       border-radius: 50%;
       margin-right: 10px;
     }
     .applications-name--wrapper {
       display: flex;
       flex-direction: column;
       span {
         margin: 0;
         line-height: 17px;
         font-size: 14px;
       }
     }
   }
    .application-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      .v-btn {
        margin: 0 4px;
      }
    }
  }
}
</style>
